<template lang="html">
  <div class= 'card'>
  		<div class = 'card-header'>
  			<h1>{{title}}</h1>
  			<!-- <i v-bind:class = 'icon'></i> -->
  		</div>
      <div class="card-reading-time">
        <i>{{readingTime}} minute read</i>
      </div>
  		<div class = 'card-content'>
  			<p class = 'card-content-text'>{{message}}
        </p>
  		</div>
  		<div class = 'card-footer'>

  		</div>
	</div>
</template>

<script>
export default {
  data: function(){
		return {
      clicked:false
    }
	},
	props: {
		title: String,
		message:String,
		icon:String
	},
	methods: {
	},
  computed: {
    readingTime() {
      // words / 200
      var wordCount = this.message.match(/(\w+)/g).length
      console.log('words: ', wordCount);
      var readingTimeStep1 = wordCount / 200
      console.log('step1', readingTimeStep1);
      var readingTimeStep2 = Math.floor(readingTimeStep1) //get only the integer value (round downwards)
      console.log('step2', readingTimeStep2);
      var decimalPart = readingTimeStep1 - readingTimeStep2
      var seconds = decimalPart * 0.60
      // rounding
      if (seconds >= 30) {
        return readingTimeStep2 +1
      } else {
        if (readingTimeStep2 == 0) {
          return readingTimeStep2 + 1 //min 1 minute
        }
        return readingTimeStep2
      }
    }
  },
}

</script>

<style lang="css" scoped>

h1 {
  font-size: 1.25rem;
  margin-bottom: 0px;
}

.card {
  width: 24%;
  height: 150px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
  margin: 0.5%;
  overflow: hidden;
}

.card-header{
}

.card-content{
  font-size: .875em;
  color: #6c757d;
  padding: 3px;
}

.card-content-text{
  margin-top: 3px;
  margin-bottom: 2px;
}


.card-reading-time {
  font-size: .875em;
  color: #052c65;
}

.card-footer{

}

/* todo changing colors every second item*/
/* .card:nth-child(1){
	background-color:#3498db;

	div.card-header{
		background-color:#2980b9;
	}
}

.card:nth-child(2){
	background-color:#34495e;

	div.card-header{
		background-color:#2c3e50;
	}
}

.card:nth-child(3){
	background-color:#9b59b6;

	div.card-header{
		background-color:#8e44ad;
	}
} */

</style>
