<template lang="html">
  <div class="main" v-if="!loading">
    <ModalUploadedFiles
      v-if="showUploads"
      @close="closeUploads"/>
    <!-- </ModalUploadedFile> -->

    <div class="row-toggle">

    </div>

    <div class="row">
      <div class="col-1" v-if="!showMenu">
        <button class="btn btn-light" title="Toggle Menu" @click="toggleMenu">
          <font-awesome-icon :icon="['fas', 'bars']" size="1x"/>
        </button>
      </div>

      <div class="col-2" v-if="showMenu">
        <!-- TODO CLeanup below line -->
      <!-- <div class="leftlane" v-if="showMenu"> -->

        <button class="btn btn-light" title="Toggle Menu" @click="toggleMenu">
          <font-awesome-icon :icon="['fas', 'bars']" size="1x"/>
        </button>


        <h2>Workspaces</h2>
        <ul id="Tree">
          <TreeItem
            ref="treeCompRef"
            class="tree"
            :folders="folders"
            :change="change"
            :documents="documents"
            :currentDocument="currentDocument"
            @openDocument="openDocument"
            @filter="setFilter"
            @createDoc="createDoc"
            @delete="closeEditor"
            @uploads="displayUploads">
          </TreeItem>

         </ul>
      </div>

      <div class="col-10">
      <!-- TODO cleanup -->
      <!-- <div v-bind:class="{ 'content-full': !showMenu, 'content': showMenu}"> -->

        <!-- Search is triggered in watcher -> delayed -->
        <input v-if="displayDocuments"
        class="form-control"
          v-model="searchString"
          placeholder="Enter text to find your documents..">


        <div class="row">

          <div class="createButton"
            v-if="documents.length < 1 && !displayEditor">
            <button @click="createDoc">
              <font-awesome-icon :icon="['fas', 'file']" size="2x"/>
              <br>
              Create Document
            </button>
          </div>
        </div>

        <div id="documents" v-if="displayDocuments">

          <div class="row">
              <DocumentCard
              v-for="entry in documents"
              :key="entry.id"
              :title="entry.name"
              :message="entry.text"
              draggable="true"
              @dragstart="$refs.treeCompRef.startDrag($event, entry)"
              @click="openDocument($event, entry)"
              />
            </div>
        </div>

        <div id="document" v-if="displayEditor">

          <div class="row">
            <h2>{{currentDocument.name}}</h2>
          </div>

          <div class="urls"
            v-if="typeof(currentDocument.isShared) !== 'undefined' && currentDocument.isShared == true">
            <label>Public Link: </label>
            <a href="#" @click.prevent="showShared">{{currentDocument.link}}</a>

            <!-- TODO REPLACE URL WITH DOMAIN -->
            <div class="url">
              <label>Full URL: </label>
              <span id="fullURL">http://localhost:8080/shared?docId={{currentDocument.link}}</span>

              <button class="btn btn-outline-primary btn-sm" @click.prevent="copy('fullURL')">Copy</button>
            </div>
          </div>


          <div class="buttons">
            <button class="btn btn-secondary"
             @click="exportToMD" title="Export to Markdown">
              <font-awesome-icon :icon="['fas', 'file-export']" size="1x"/>
            </button>

            <button class="btn btn-secondary"
            @click="exportToHTML" title="Export to HTML">
              <font-awesome-icon :icon="['fas', 'file-export']"
              style="color:#7cacff"
              size="1x"/>
            </button>
            <button class="btn btn-secondary"
            @click="share()" title="Share" v-if="typeof(currentDocument.id) != 'undefined' && currentDocument.id">
              <font-awesome-icon :icon="['fas', 'share-alt']"
              style=""
              size="1x"/>
            </button>
            <button class="btn btn-secondary"
            @click="unshare()" title="Stop sharing" v-if="typeof(currentDocument.isShared) !== 'undefined' && currentDocument.isShared == true">
              <font-awesome-icon :icon="['fas', 'minus-circle']"
              style="color:#e01e1e"
              size="1x"/>
            </button>

            <button class="btn btn-outline-primary"
            @click="closeEditor">Close</button>
          </div>

          <div class="row">
            <v-md-editor
              class="editor"
              v-model="currentDocument.text"
              :disabled-menus="[]"
              left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link image code | save | customToolbar"
              :toolbar="toolbar"
              @upload-image="handleUploadImageFromEditor"
              @save="saveDoc"
              @change="saveChange">
            </v-md-editor>

            <span v-if="uploadProgress !=='' ">{{uploadProgress}}</span>
          </div>

        </div>

      </div>
    </div>

<!-- end of main div -->
  </div>

  <div class="loader"  v-if="loading">
    <h1>loading...</h1>
    <div class="lds-dual-ring"></div>
  </div>


</template>

<script>
import TreeItem from '@/components/TreeItem.vue';
import DocumentCard from '@/components/DocumentCard.vue';
import ModalUploadedFiles from '@/components/ModalUploadedFiles.vue';
import firebase from "firebase/app";

export default {
  props: ['workbook'],
  components: {
    TreeItem,
    DocumentCard,
    ModalUploadedFiles,
  },
  data() {
    return {
      // text: '',
      // title: '',
      displayEditor: false,
      displayDocuments: true,
      currentDocument: {},
      documents: [],
      documentsRemovedBySearch: [],
      folders: [],
      loading: true,
      change: 0, // used as key for TreeItem to force reload
      activeFolder: "",
      parentFolder: "",
      paginationLastEntry: {},
      showUploads: false,
      uploadProgress: "",
      searchString: "",
      timer: null,
      latestTextChange: "",
      showMenu: true,
    }
  },
  created() {
    var db = this.$store.state.db
    var userDocRef = db.collection('user').doc(this.$store.state.user.uid)

    // Listen for updates in folder structure
    userDocRef.collection("writer_folders").onSnapshot((querySnapshot) => {
      console.log("Folder snapshot found");
      const changes = querySnapshot.docChanges()
      changes.forEach((change, i) => {
        var folder = change.doc.data()

        //listen to changes on subFolders
        userDocRef.collection("writer_folders").doc(folder.id).collection('subFolders').onSnapshot((subFolderSnapshot) => {
          subFolderSnapshot.docChanges().forEach((subChange, i) => {
            if (subChange.type == 'added' || subChange.type == 'modified') {
              try {
                // try to find and update element
                var element =
                folder.subFolders.find((element) => {
                  if (element.id == subChange.doc.id) {
                    //update eleme
                    element = subChange.doc.data()

                    // required to force child reload
                    this.change = Date.now()

                    console.log('subFolder updated, ', subChange.doc.id)
                    return element
                  }
                })
                if (!element) {
                  throw 'not found, push item'
                }
              } catch (e) {
                //error during find add
                if (typeof(folder.subFolders) == "undefined") {
                  folder.subFolders = []
                }
                folder.subFolders.push(subChange.doc.data())
                var index = this.folders.findIndex(item => item.id == folder.id)

                // required to force child reload
                this.change = Date.now()

                console.log("changed subFolder found and adapted in list, ", subChange.doc.id);
              }
            } else {
              // delete
              var index = folder.subFolders.findIndex(item => item.id == subChange.doc.id)
              folder.subFolders.splice(index, 1)
              // required to force child reload
              this.change = Date.now()
            }
          });

          //IMPORTANT: Folder has to be pushed at end of handling subfolder, otherwise vue does not recognise change
          if (change.type == 'added' || change.type == 'modified') {
            try {
              // try to find and update element
              var element =
              this.folders.find((element) => {
                if (element.id == change.doc.id) {
                  //update eleme
                  element = folder
                  console.log('changed folder found and adapted in list', change.doc.id)
                  return element
                }
              })
              if (!element) {
                throw 'not found, push item'
              }
            } catch (e) {
              //error during find add
              if (typeof(this.folders) == "undefined") {
                this.folders = []
              }
              this.folders.push(folder)
              console.log("folder found and added to list: ", change.doc.id);
            }
          }else {
            // delete
            var index = this.folders.findIndex(item => item.id == change.doc.id)
            this.folders.splice(index, 1)
          }
        }) //snapshot of subfolders
      }); //snapshot of folders
      this.loading = false

    }); //reading folders

    //read documents
    this.loadDocuments(false)
  }, //end created
  watch: {
    searchString: function (newString, oldString) {
      var context = this, args = arguments;
      clearTimeout(this.timer);
      this.timer = setTimeout(function () {
        context.search();
      }, 500);
    }
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
    copy(elementId) {
      /* Get the text field */
      var copyText = document.getElementById(elementId);

      console.log(copyText.innerHTML);
      //due to issues with copying text from input field i create a new textarea and remove it again.. hacky and dirty but works
      const el = document.createElement('textarea');
      el.value = copyText.innerHTML;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      /* Alert the copied text */
      window.alert("Copied the text: " + copyText.innerHTML);
    },
    exportToMD() {
      var mdText = this.currentDocument.text
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/markdown;charset=utf-8,' + encodeURIComponent(mdText));
      element.setAttribute('download', this.currentDocument.name
    +'.md');

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    exportToHTML() {
      var mdHTML = this.currentDocument.html
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/html;charset=utf-8,' + encodeURIComponent(mdHTML));
      element.setAttribute('download', this.currentDocument.name+'.html');

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    async search(event) {
      if (this.searchString != "") {
        var search = {
        	'q' : this.searchString,
        	'query_by': 'name, text',
          'num_typos': 0,
        }
        console.log(search);
        var searchResults = await
        this.$store.state.client.collections(this.$store.state.userSchema).documents().search(search)
        console.log(searchResults);
        this.loadDocsForSearchResult(searchResults)
      } else {
        //show all docs
        console.log("show all");
        for (var i = 0; i < this.documentsRemovedBySearch.length; i++) {
          this.documents.push(this.documentsRemovedBySearch[i])
        }
        this.documentsRemovedBySearch = []
      }
    },
    loadDocsForSearchResult(searchResults) {
      // search simply applies on loaded docs and removes not fitting results from array
      // in case folder filter is set, then only docs in folder are considered for removal
      console.log('Documents fitting to search string: ', searchResults.found);

      // filter documents for found elements
      var i = this.documents.length
      while (i--) {
        let foundElement = searchResults.hits.find((element) => {
          if (element.document.id == this.documents[i].id) {
              return element
          }
        })
        if (!foundElement || typeof(foundElement) == "undefined") {
          // push to removed array
          this.documentsRemovedBySearch.push(this.documents[i])
          // remove from array
          this.documents.splice(i, 1)
        }
      }

      // check removed documents and add back to display depending on search results
      var x = this.documentsRemovedBySearch.length
      while (x--) {
        let foundElement = searchResults.hits.find((element) => {
          if (element.document.id == this.documentsRemovedBySearch[x].id) {
              return element
          }
        })
        if (foundElement || typeof(foundElement) != "undefined") {
          // push to removed array
          this.documents.push(this.documentsRemovedBySearch[x])
          // remove from array
          this.documentsRemovedBySearch.splice(x, 1)
        }

        console.log('Documents displayed: ', this.documents.length);
        console.log('Documents hidden: ', this.documentsRemovedBySearch.length);
      }
    },
    handleUploadImageFromEditor(event, insertImage, files) {
      // Get the files and upload them to the file server, then insert the corresponding content into the editor
      console.log(files);
      // get storage object
      var storage = this.$store.state.storage
      // get storage ref
      var storageRef = storage.ref();
      // get ref to folder and user specific folder
      var userDirRef = storageRef.child('user').child(this.$store.state.user.uid)

      //uplaod files
      for (var i = 0; i < files.length; i++) {
        // get ref for file to upload
        var fileRef = userDirRef.child(files[i].name)
        //upload
        var uploadTask = fileRef.put(files[i])

        uploadTask.on('state_changed', (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.uploadProgress = 'Upload is ' + progress + '% done'
          console.log(this.uploadProgress);
        },
        (error) => {
          console.log('Error during upload: ', error);
        },
        () => {
          //upload successfull
          console.log('Image upload successful');
          this.uploadProgress = ""
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('File available at', downloadURL);
            insertImage({
              url: downloadURL,
              desc: 'Enter your text',
              width: 'auto',
              height: 'auto',
            });
          });
        })
      }

    },
    displayUploads() {
      this.showUploads = true
      this.closeEditor()
    },
    closeUploads() {
      this.showUploads = false
    },
    setFilter(activeFolder, parentFolder) {
      // set filter... filter will be applied in query => created
      this.activeFolder = activeFolder
      this.parentFolder = parentFolder
      //remove search input
      this.searchString = ""
      //apply filter and read docs
      this.loadDocuments(false)
    },
    // document handling
    createDoc() {
      this.currentDocument = {}
      this.displayEditor = true
      this.displayDocuments = false
    },
    closeEditor() {
      this.displayEditor = false
      this.displayDocuments = true
      this.currentDocument = {}
    },
    openDocument(event, doc) {
      this.currentDocument = doc
      this.displayEditor = true
      this.displayDocuments = false
      this.$refs.treeCompRef.markAndOpen(null, this.currentDocument)
    },
    share() {
      //handle sharing ->  new or update
      console.log('share called');
      var db = this.$store.state.db
      var sharedColRef = db.collection("shared")
      // create shared doc
      var sharedDocRef = sharedColRef.doc()
      sharedDocRef.set({
        'id': sharedDocRef.id,
        'html': this.currentDocument.html,
        'name': this.currentDocument.name,
        'text': this.currentDocument.text,
        'updated': Date.now(),
        'user': this.$store.state.user.uid
      })
      .then(() => {
        // add search index
        this.handleSharedIndex(sharedDocRef.id, true, false)
        console.log('Document shared: ', sharedDocRef.id);

        // create shared link
        // set isShared = true
        this.currentDocument.link = sharedDocRef.id
        this.currentDocument.isShared = true
        // save document
        this.saveDoc(this.currentDocument.text, this.currentDocument.html, true)
      })
    },
    unshare() {
      // delete doc
      var db = this.$store.state.db
      var sharedColRef = db.collection("shared")
      sharedColRef.doc(this.currentDocument.link).delete()
      .then(()=> {
        console.log('Shared document removed');
        // remove search index
        this.handleSharedIndex(this.currentDocument.link, false, true)

        // remove link
        this.currentDocument.link = ""
        // set isShared = false
        this.currentDocument.isShared = false
        // save changes
        this.saveDoc(this.currentDocument.text, this.currentDocument.html)
      })
    },
    showShared() {
      this.$router.push({ path: '/shared', query: {docId: this.currentDocument.link} })
    },
    handleSharedIndex(id, newIndex=false, deleteIndex=false,) {
      console.log(this.currentDocument.text, id);
      if (deleteIndex) {
        //delete index for full text search
        this.$store.state.client.collections('shared').documents(id).delete()
        .then(()=> {
          console.log('Shared index deleted');
        })
      } else {
        if (newIndex) {
          // create new index based on current doc text
          var indexDoc = {
              'id': id,
              'text': this.currentDocument.text
          }
          this.$store.state.client.collections('shared').documents().create(indexDoc)
          .then(()=>{
            console.log('Shared index created');
          })
        } else {
          // update current doc with new text
          var indexDoc = {
              'id': id,
              'text': this.currentDocument.text
          }
          this.$store.state.client.collections('shared').documents(id).update(indexDoc)
          .then(() => {
              console.log('Shared index updated');
          })
          .catch((error) => {
            //add new index in case of error
            this.$store.state.client.collections('shared').documents().create(indexDoc)
            console.log('Shared index created after unsuccessful update');
          })
        }
      }
    },
    saveChange(text, html) {
      // cancel old timer => to avoid too many saves
      clearTimeout(this.timer)
      // set new timer if change exists
      if (this.currentDocument.html != html) {
        this.timer = setTimeout(() => {this.saveDoc(text,html)}, 5000)
      }
    },
    saveDoc(text, html, newShare=false) {
      // save or update document
      console.log('save');
      var db = this.$store.state.db
      var userDocRef = db.collection("user").doc(this.$store.state.user.uid)
      // determine target folder
      var folder = "" // default folder
      var subFolder = "" //default subFolder

      if (this.currentDocument.hasOwnProperty('folder')) {
        folder = this.currentDocument.folder
        if (this.currentDocument.hasOwnProperty('subFolder')) {
          subFolder = this.currentDocument.subFolder
        }
      }

      //set title on save if not already set
      var firstLine = "";
      var lineNo = 0;
      while (firstLine == "") {
        firstLine = this.currentDocument.text.split('\n')[lineNo];
        lineNo = lineNo + 1
        if (lineNo > 10) {
          break
        }
      }
      //shorten title
      if (firstLine.length > 25) {
        firstLine = firstLine.substr(0, 25) + '...'
      }
      this.currentDocument.name = firstLine

      // check if document has id, if yes update, else create
      if (this.currentDocument.hasOwnProperty('id')) {
        // update document
        var newDocRef = userDocRef.collection("writer").doc(this.currentDocument.id)
        // set shared flag
        var isShared = false
        if (typeof(this.currentDocument.isShared) !== 'undefined') {
          isShared = this.currentDocument.isShared
        }
        //set link
        var link = ""
        if (typeof(this.currentDocument.link) !== 'undefined') {
          link = this.currentDocument.link
        }
        //set data to update
        var updateDocData = {
          'id': newDocRef.id,
          'name': this.currentDocument.name,
          'text': this.currentDocument.text,
          'html': html,
          'folder': folder,
          'subFolder': subFolder,
          'updatedOn:': new Date(),
          'isShared': isShared,
          'link': link,
        }
        newDocRef.set(updateDocData, {merge: true})
        .then(() => {
            console.log("Document successfully updated");

            //update index for full text search
            var indexDoc = {
                'id': updateDocData.id,
                'name': updateDocData.name,
                'text': updateDocData.text
            }
            console.log(this.$store.state.userSchema);
            console.log(updateDocData.id);
            this.$store.state.client.collections(this.$store.state.userSchema).documents(updateDocData.id).update(indexDoc)
            .catch((error) => {
              console.log('Index update failed, create index');
              // in case document can't be updated it might not exist so create
              this.$store.state.client.collections(this.$store.state.userSchema).documents().create(indexDoc)
            })
            // update shared document if shared
            if (this.currentDocument.isShared && newShare == false) {
              var db = this.$store.state.db
              var sharedColRef = db.collection("shared")
              var sharedDocRef = sharedColRef.doc(this.currentDocument.link)
              sharedDocRef.set({
                'html': this.currentDocument.html,
                'name': this.currentDocument.name,
                'text': this.currentDocument.text,
                'updated': Date.now(),
              }, {merge: true})
              .then(() => {
                // update search index
                console.log('Update not possible, auto re-create');
                this.handleSharedIndex(sharedDocRef.id, false, false)
                console.log('Shared doc updated: ', sharedDocRef.id);
              })
            }

            //load Docs
            this.loadDocuments(false, true)
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
        });

      } else {
        // Create new document
        if (this.parentFolder !== "") {
          subFolder = this.activeFolder
          folder = this.parentFolder
        } else {
          folder = this.activeFolder
        }
        var newDocRef = userDocRef.collection("writer").doc()
        newDocRef.set({
          'id': newDocRef.id,
          'name': this.currentDocument.name,
          'text': this.currentDocument.text,
          'html': html,
          'folder': folder,
          'subFolder': subFolder,
          'createdOn:': new Date()
        })
        .then(() => {
            console.log("Document successfully created");
            // read updated document and assign to currentDocument to prevent creation of new doc every time save is triggered
            userDocRef.collection("writer").doc(newDocRef.id).get()
            .then((doc) =>{
              this.currentDocument = doc.data()

              //create index for full text search
              var indexDoc = {
                  'id': this.currentDocument.id,
                  'name': this.currentDocument.name,
                  'text': this.currentDocument.text
              }
              this.$store.state.client.collections(this.$store.state.userSchema).documents().create(indexDoc)
            })
            .catch((error) => {
              console.error();("New doc could not be read.");
              this.closeEditor();
            })
            // set id for currentDocument to avoid that same doc gets created again
            this.loadDocuments(false, true)
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
        });
      }

    },
    loadDocuments(nextEntries=false, displayEditor=false) {
      //load documents => nextEntries == ture => next pagination entries

      //set db
      var db = this.$store.state.db
      var userDocRef = db.collection('user').doc(this.$store.state.user.uid)

      console.log('Load docs for filter:', this.parentFolder, this.activeId)
      var limit = 50 //entries for pagination

      // display documents and close editor
      //TODO save document before showing something else
      if (!displayEditor) {
        this.displayEditor = false
        this.displayDocuments = true
      }

      // listen do documents and collections in writer collection
      var subFolder = ""
      var folder = ""
      if (this.parentFolder !== "") {
        subFolder = this.activeFolder
        folder = this.parentFolder
      } else {
        folder = this.activeFolder
      }

      var query = userDocRef.collection('writer')
      //build querey
      if (folder !== "") {
        // query with folder
        query = query.where('folder', '==', folder)
      }
      if (subFolder !== "") {
        //query with subfolder
        query = query.where("subFolder", "==", subFolder)
      }

      if (nextEntries == false) {
        this.documents = []
        query.orderBy("name").limit(limit)
        .get()
        .then((querySnapshot) => {

          //set last entry for pagination
          this.paginationLastEntry = querySnapshot.docs[querySnapshot.docs.length-1]
          // fill documents array
          querySnapshot.forEach((doc, i) => {
            this.documents.push(doc.data())
          });
        }) //end then
        .catch((error) => {
          console.log("Error getting documents; ", error);
          this.$toast.warning('Error loading documents. Please refresh page.', {  position: "top"})
        }) //end catch

      } else {

        // get next pagination entries
        console.log('Get next pagination entries');
        query.startAfter(this.paginationLastEntry).orderBy("name").limit(limit)
        .get()
        .then((querySnapshot) => {
          // attach documents to array
          querySnapshot.forEach((doc, i) => {
            this.documents.push(doc.data())
          });
        })
        .catch((error) =>{
          console.log("Error getting next documents:", error);
          this.$toast.warning('Error loading documents. Please refresh page.', {  position: "top"})
        })

      }

    }
  },
}
</script>


<style lang="css" scoped>
#Tree{
  padding-left: 0rem;
}

.urls {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.urls label{
  margin-right: 1rem;
}

.urls span{
  margin-right: 1rem;
}

.row {
  display:flex;
  flex-wrap: wrap;
  justify-content:center;
  margin-top:20px;
}
.row-toggle {
  display:flex;
  justify-content:left;
  margin-top:20px;
}


.main {
  width: 100%;
}


.leftlane {
  float: left;
  width: 200px;
}
.content {
  float: right;
  width: calc(100% - 200px);
}
.content-full {
  float: center;
  width: 100%;
}

.editor {
  min-height: 640px;
}

.loader {
  position: absolute;
  height: 500px;
  left: 50%;
  top: 20%;
}

/* loader css */
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #5a80e3;
  border-color: #5a80e3 transparent #5a80e3 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.createButton {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
