<template lang="html">
  <teleport to="body">
    <div class="backdrop" @click.self="$emit('close')">
      <div class="custom-modal">
        <!-- TODO -->
        <h1>Manage uploaded files</h1>
        <br>
        <h3>Upload new files</h3>
        <div class="upload-section">
          <input class="form-control file-input" type="file"
            id="file"
            ref="myFiles"
            @change="uploadFiles" multiple>
          <span v-if="uploadProgress !=='' ">{{uploadProgress}}</span>
        </div>
        <br>
        <h3>Edit, download or copy link of existing files</h3>
        <div class="row">
          <div class="col-5 image-section" v-for="(image, index) in images" :key="index">
              <div class="row">
                <div class="buttons">
                  <button class="btn btn-outline-primary" @click.prevent="copy(index)">Copy Link</button>
                  <button class="btn btn-outline-primary" @click.prevent="download(image)">Download</button>
                  <button class="btn btn-outline-primary" @click.prevent="deleteImage(image, index)">Delete</button>
                </div>
              </div>

              <div class="row">
                <input class="form-control" :id="index" :value="image" disabled/>
              </div>

              <div class="row">
                <img :src="image" class="img-fluid">
              </div>

          </div>

        </div>

      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  props: {
  },
  emits: ['close'],
  data: function() {
    return {
      images: [],
      files: [],
      uploadProgress: "",
    }
  },
  computed: {
  },
  created() {
    this.loadFiles()
  },
  methods: {
    loadFiles() {
      // clear images for reload
      this.images = []
      // get storage
      var storage = this.$store.state.storage
      // get storage reference
      var storageRef = storage.ref();
      // get reference to user folder / create if non-existent
      var imagesRef = storageRef.child('user').child(this.$store.state.user.uid);
      imagesRef.listAll()
      .then((res) => {
        console.log(res.items);
        for (var i = 0; i < res.items.length; i++) {
          // fill array
          res.items[i].getDownloadURL()
          .then((downloadURL) => {
            this.images.push(downloadURL)
          })
        }
      })
      .catch((error) => {
        // TODO error message
        console.log("Error during read of images: ", error);
      })
    },
    uploadFiles() {
      // TODO upload functionality
      this.files = this.$refs.myFiles.files
      console.log(this.files);
      // files are blob so to upload:
      // Create a root reference
      var storage = this.$store.state.storage;
      var storageRef = storage.ref();

      //process files
      var pathToFiles = 'user/'+this.$store.state.user.uid+'/'
      for (var i = 0; i < this.files.length; i++) {
        var newFile = storageRef.child(pathToFiles+this.files[i].name)

        //upload file from Blob or file API
        var uploadTask = newFile.put(this.files[i])
        uploadTask.on('state_changed', (snapshot) => {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.uploadProgress = 'Upload is ' + progress + '% done'
          console.log(this.uploadProgress);
        },
        (error) => {
          console.log('Error during upload: ', error);
        },
        () => {
            // upload successfull
            console.log('Image upload succesful');
            // this.change = Date.now()
            //TODO success message
            //TODO trigger reload or update of list..
            this.loadFiles()
        })
      } // endfor
    },
    close() {
      //close modal
      this.$emit('close')
    },
    copy(index) {
      // TODO copy link
      /* Get the text field */
      var copyText = document.getElementById(index);

      //due to issues with copying text from input field i create a new textarea and remove it again.. hacky and dirty but works
      const el = document.createElement('textarea');
      el.value = copyText.value;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      /* Alert the copied text */
      alert("Copied the text: " + copyText.value);
    },
    download(image) {
      // download image from download url
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        var blob = xhr.response;
        console.log(blob);
        const a = document.createElement('a');
        a.setAttribute('download', 'image');
        a.setAttribute('href', window.URL.createObjectURL(blob));
        a.click(); // EXECUTING CLICK EVENT WILL AUTO-DOWNLOAD THE FILE
      }
      xhr.open('GET', image)
      xhr.send();

    },
    deleteImage(image, index) {
      // get storage
      var storage = this.$store.state.storage
      // Create a reference to the file to delete
      var imageRef = storage.refFromURL(image);

      // Delete the file
      imageRef.delete().then(() => {
        // File deleted successfully
        //TODO success message
        // remove image from Array
        this.images.splice(index, 1)
        console.log('file deleted successfully');
      }).catch((error) => {
        // Uh-oh, an error occurred!
        //TODO error message
        console.log('Error during deletion: ', error);
      });
    }
  }
}
</script>

<style lang="css" scoped>

.backdrop {
  /* starts directly from top with 0 pixel */
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0,0,0,0.5) !important;
  width: 100%;
  height: 100%
}

.custom-modal {
  margin-top: 30%;
  width: 70vw;
  height: 90vh;
  /* margin: 0 auto; */
  background: #fff;
  border-radius: 10px;
  position: absolute;
  float: left;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
  overflow-x: auto;
}

.custom-modal .row {
  padding-top: 1rem;
}



.image-section {
  border: dotted;
  border-width: thin;
  border-radius: 5px;
  border-color: #cfe2ff;
  margin: 4%;
}

.image-section:only-child {
  margin-right: 0rem;
}

</style>
