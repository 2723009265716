<template lang="html">

    <h1 v-if="showDocument==false">Find interesting content</h1>

    <!-- full text search on enter -->
    <!-- Search method called in watcher with delayed execution -->
    <input class="form-control" id="searchString"
      v-model="searchString"
      placeholder="Enter text to find interesting article.."
      v-if="showDocument==false">

    <!-- list top 20 and then endless scroll -->
    <div class="documents" v-if="showDocument==false && showSearchResults==false" ref="scrollComponent">
      <div class="row">
        <!-- TODO adapt based on document -->
        <DocumentCard
          v-for="entry in documents"
          :key="entry.id"
          :title="entry.name"
          :message="entry.text"
          @click="displayDocument(entry)"
        />
      </div>
      <!-- will load additional documents on button click -->
      <div class="row" v-if="lastVisibleDoc !== null">
        <button @click.prevent="getNext($event)">Load more...</button>
      </div>
    </div>

    <!-- list search results -->
    <div class="documents" v-if="showDocument==false && showSearchResults==true">
      <div class="row">
        <p>{{found}} Documents found based on search string</p>
      </div>
      <div class="row">
        <!-- TODO adapt based on document -->
        <DocumentCard
          v-for="entry in foundDocuments"
          :key="entry.id"
          :title="entry.name"
          :message="entry.text"
          @click="displayDocument(entry)"
        />
      </div>
    </div>

    <div class="document" v-if="showDocument">
      <button class="btn btn-outline-primary" @click.prevent="closeDoc">Back</button>

      <!-- Editor preview -->
      <v-md-preview :text="selectedDocument.text"></v-md-preview>

    </div>



</template>

<script>
import DocumentCard from '@/components/DocumentCard.vue'

export default {
  components: {
    DocumentCard
  },
  data() {
    return {
      searchString: "",
      documents: [],
      lastVisibleDoc: null,
      showDocument: false,
      selectedDocument: {},
      foundDocuments: [],
      showSearchResults: false,
      found: 0,
      timer: 0,
      queryLimit: 40,
    }
  },
  created() {
    // load first 20 docs
    var db = this.$store.state.db
    var collectionRef = db.collection('shared')
    var first = collectionRef.orderBy('updated').limit(this.queryLimit)

    first.get()
    .then((documentSnapshots) => {
      if (documentSnapshots.docs.length<this.queryLimit) {
        this.lastVisibleDoc = null; // no more to load
      } else {
        this.lastVisibleDoc = documentSnapshots.docs[documentSnapshots.docs.length-1];
      }

      for (var i = 0; i < documentSnapshots.docs.length; i++) {
        this.documents.push(documentSnapshots.docs[i].data())
      }
    })
  },
  mounted() {
    // open document for passed parameter
    if (this.$route.query.docId !== undefined) {
      var db = this.$store.state.db
      console.log('value changed: ', this.$route.query.docId);
      db.collection('shared').doc(this.$route.query.docId).get()
      .then((documentSnapshot)=> {
        var entry = documentSnapshot.data()
        this.displayDocument(entry)
      })
      .catch((error) => {
        // TODO error message
        console.log('Error loading document ', error);
      })
    }

    // removed scroll listener
    // //set scroll listener for infite scroll
    // window.addEventListener("scroll", this.getNext)
  },
  unmounted() {
    // removed scroll listener
    // window.removeEventListener("scroll", this.getNext)
  },
  watch: {
    searchString: function (newString, oldString) {
      var context = this, args = arguments;
      clearTimeout(this.timer);
      this.timer = setTimeout(function () {
        context.search();
      }, 500);
    },
  },
  methods: {
    getNext(e) {
      // check if there is something to load
      if (this.lastVisibleDoc == null) {
        console.log('No load, no new docs');
        return 'nothing to load'
      }

      // removed old scroll logic
      // let element = this.$refs.scrollComponent
      // if ( element.getBoundingClientRect().bottom < window.innerHeight ) {

      // load next batch
      if (this.lastVisibleDoc !== null) {
        // Construct a new query starting at this document,
        // get the next 25 cities.
        var db = this.$store.state.db
        var collectionRef = db.collection('shared')

        var next = collectionRef.orderBy("updated").startAfter(this.lastVisibleDoc).limit(this.queryLimit);

        next.get()
        .then((documentSnapshots) => {
          // check if more docs to load.
          if (documentSnapshots.docs.length<this.queryLimit) {
            this.lastVisibleDoc = null; // no more to load
          } else {
            this.lastVisibleDoc = documentSnapshots.docs[documentSnapshots.docs.length-1];
          }

          documentSnapshots.forEach((doc) => {
            this.documents.push(doc.data())
          })
        })
     }

    },
    async search() {
      // clear documents
      this.foundDocuments = []

      // search
      if (this.searchString != "") {
        this.showSearchResults = true
        var search = {
        	'q' : this.searchString,
        	'query_by': 'text',
          'num_typos': 0,
        }
        var searchResults = await
        this.$store.state.client.collections('shared').documents().search(search)
        console.log(searchResults);

        // search docs via id
        var db = this.$store.state.db
        this.found = searchResults.found
        for (var i = 0; i < searchResults.hits.length; i++) {
          var docRef = db.collection('shared').doc(searchResults.hits[i].document.id)
          docRef.get()
          .then((doc) => {
            if (doc.exists) {
              this.foundDocuments.push(doc.data())
            } else {
              // TODO proper error message
              console.log("No such document", searchResults.hits[i].document.id);
            }
          })
          .catch((error) => {
            console.log('Error getting document: ', error);
          })
        }

      } else {
        //show all docs
        this.found = 0
        this.showSearchResults = false
        console.log("show all");
      }
    },
    displayDocument(entry) {
      console.log('triggered');
      this.showDocument = true
      this.selectedDocument = entry
    },
    closeDoc() {
      this.showDocument = false
      this.selectedDocument = {}
    }
  }
}
</script>

<style lang="css" scoped>



/* .content {
  float: left;
  background: #f1f1f1;
} */

/* .row {
  display:flex;
  flex-wrap: wrap;
  justify-content:center;
  margin-top:20px;
} */

input {
  margin-bottom: 1rem;
}

.documents {
  margin-left: 0.5rem;
  height: 100%;
}

.document {
  width: 100vw;
}
</style>
