import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// removed to use dragula.. lets see if it works
//polyfills missing drag drop events for mobile
// import 'drag-drop-touch/DragDropTouch.js'

//import global vuex store
import { store } from './store.js'


// Markdown editor based on codemirror -> js based editor
import VMEditor from '@kangc/v-md-editor/lib/codemirror-editor.js';
import '@kangc/v-md-editor/lib/style/codemirror-editor.css';
// import preview view for shared docs
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';

import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
// import EN language
import enUS from '@kangc/v-md-editor/lib/lang/en-US';
// Resources for the codemirror editor
import Codemirror from 'codemirror';
// mode
import 'codemirror/mode/markdown/markdown';
// placeholder
import 'codemirror/addon/display/placeholder';
// active-line
import 'codemirror/addon/selection/active-line';
// scrollbar
import 'codemirror/addon/scroll/simplescrollbars';
import 'codemirror/addon/scroll/simplescrollbars.css';
// style
import 'codemirror/lib/codemirror.css';
// icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFolder, faFile, faEdit, faTrash, faFolderOpen, faFileAlt, faFileImage, faFileExport, faShareAlt, faMinusCircle, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

//import typesense for full text search
import 'typesense/dist/typesense.min.js'

//import toasts for error messages
import Toaster from "@meforma/vue-toaster";

// Cookie handler
import VueCookies from 'vue3-cookies'

// import bootstrapt - no js
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

// add icons to library for later use
library.add(faFolder)
library.add(faFile)
library.add(faEdit)
library.add(faTrash)
library.add(faFolderOpen)
library.add(faFileAlt)
library.add(faFileImage)
library.add(faFileExport)
library.add(faShareAlt)
library.add(faMinusCircle)
library.add(faBars)

VMEditor.Codemirror = Codemirror;
VMEditor.use(vuepressTheme);
VMEditor.lang.use('en-US', enUS); //set en as default language
// set preview
VMdPreview.use(vuepressTheme);

console.log(VMdPreview)

// get/set global auth instance
store.commit('setGlobalFBInstances')
// Start of APP definition & re-render in case of state change
var app
store.state.auth.onAuthStateChanged(user => {
  if (!app) {
    app = createApp(App)
    // register router
    app.use(router)

    // register cookie handler
    app.use(VueCookies);
    // register MarkdownEditor
    app.use(VMEditor);
    // register preview component
    app.use(VMdPreview)
    //register store
    app.use(store)
    // register icons
    app.component('font-awesome-icon', FontAwesomeIcon)
    // register toasts
    app.use(Toaster)

    // mount app
    app.mount('#app')
  }
  store.commit('updateUser', user)
})
