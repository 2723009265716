<template lang="html">
  <div class="main-content">
    <div class="text-content">

      <div v-if="user">
        <h1>Welcome {{user.displayName}} to PlanInk</h1>
        <p>I hope you enjoy your experience. If you have proposals for improvement please let me know. </p>
        <!-- TODO feedback option -->

      </div>

      <div v-if="!user">
        <h1>Welcome to Planink</h1>
      </div>

      <p>PlanInk is a free to use planning and writing application to make you more productive.</p>

      <h2>Try it out! It's free!</h2>
      <p>I offer this service for free. Initially I build the app for myself... after a while I thought it has functionality which I find quite useful and maybe someone else might like it as well. Hosting and running such an online tool creates some costs. With some coffee donations I should be able to cover this :)<br>
      <br>
      In case you like the service, you can support me buy buying a coffee via a donation on paypal :)</p>

      <form action="https://www.paypal.com/donate" method="post" target="_top">
      <input type="hidden" name="hosted_button_id" value="72R74XPQKDAQ4" />
      <input type="image" src="https://www.paypalobjects.com/en_US/DK/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
      <img alt="" border="0" src="https://www.paypal.com/en_DE/i/scr/pixel.gif" width="1" height="1" />
      </form>
      <br>

      <h2>Planner functionality</h2>
      <p>The planner allows you to create custom Kanban boards to plan and document your activitied.</p>
      <p>Here a list of key features:
        <ul>
          <li>Unlimited Boards</li>
          <li>Unlimited Swimlanes/Columns per board (However recommendation is to have less than 7 for a good user experience)</li>
          <li>Each lane can have a dedicated color</li>
          <li>Drag and drop of items</li>
        </ul>
      </p>

      <h2>Writer functionality</h2>
      <p>The writer consists of a modern Markdown editor. The Editor translates Markdown and HTML directly into a rendered HTML view so that you can immediately see how your article or notes look like. Furthermore you can upload or link images. If you want to share your article or notes with a friend you can do so by publishing it and sharing the link. All notes are full text searchable.</p>
      <p>Here a list of key features:
        <ul>
          <li>Unlimited Folders for sorting your notes & documents</li>
          <li>First and second level folders</li>
          <li>Auto save functionality</li>
          <li>Markdown and HTML support</li>
          <li>Share functionality</li>
          <li>Upload and linking of pictures</li>
          <li>Fast full text search on all your documents</li>
        </ul>
      </p>

    </div>

  </div>

</template>

<script>
export default {
  data: function() {
    return {

    }
  },
  computed: {
    user: function() {
        return this.$store.state.user
    }
  }
}
</script>

<style lang="css" scoped>
/* nothing component specific so far */
</style>
