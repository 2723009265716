<template lang="html">

  <!-- Delete Modal -->
  <teleport to="body" v-if="modalOpen">
    <div class="backdrop" @click.self="cancelDelete">
      <div class="custom_modal">
        <h1>Delete board: {{activeBoard.name}}</h1>
        <p class="delete">Are you sure you want to delete the Kanban board <strong>{{activeBoard.name}}</strong> and all its items?</p>
        <div class="buttons">
          <button class="btn btn-outline-primary" @click="confirmDelete">Yes</button>
          <button class="btn btn-outline-primary" @click="cancelDelete">No</button>
        </div>
      </div>
    </div>
  </teleport>


  <div class="content">

    <div class="toolbar">
      <button class="btn btn-secondary" title="New Board" @click="newBoard">
        <font-awesome-icon :icon="['fas', 'folder']" size="2x"/>
      </button>

      <button class="btn btn-secondary" title="Edit Board" @click="editBoard" :disabled="activeId ==''">
        <font-awesome-icon :icon="['fas', 'edit']" size="2x"/>
      </button>

      <button class="btn btn-secondary" title="Delete selected board" @click="deleteKanban" :disabled="activeId ==''">
        <font-awesome-icon :icon="['fas', 'trash']" size="2x"/>
      </button>
    </div>

    <div class="board"
      v-for="(board, index) in boards"
      :key="board.id"
      :id="board.id">
      <span
        @click="selectBoard(board)"
        :class="{active: (board.id == activeId)}"
        >
        {{board.name}}
      </span>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    boards: {
      type: Array,
      default: []
    },
    preSelected: {
      type: String,
      default: ""
    },
  },

  emits: ['boardSelected', 'newBoard', 'editBoard', 'deleteBoard'],

  data() {
    return {
      modalOpen: false,
      activeId: "",
      activeBoard: {},
      change: Date.now(),
    }
  },

  beforeUpdate() {
    //only set preSelected when no activeId

    if (this.activeId == "") {
      this.activeId = this.preSelected
      for (var i = 0; i < this.boards.length; i++) {
        if (this.boards[i].id == this.activeId) {
          this.activeBoard = this.boards[i]
          break;
        }
      }
    }//endif
  },

  watch: {
    preSelected: function(newVal, old) {
      // discard changes and show current selected board
      console.log('New pre-selected value - set to new active?');
      this.activeId = newVal
    }
  },

  methods: {
    selectBoard(board) {
      //triggered when a board is selected so that parent loads right data
      this.activeId = board.id
      this.activeBoard = board
      this.$emit('boardSelected', board)
    },

    newBoard() {
      // parent to display enter matric for new board
      this.$emit('newBoard')
    },

    editBoard() {
      // parent to display edit matrix for new board
      this.$emit('editBoard', this.activeId)
    },

    deleteKanban() {
      // open Modal
      this.modalOpen = true;
    },

    cancelDelete() {
      //cancel delete modal
      this.modalOpen = false
    },

    confirmDelete() {
      // emit delete event
      this.modalOpen = false
      this.$emit('deleteBoard', this.activeId)
    },

  }


}
</script>

<style lang="css" scoped>

.content {
  cursor: pointer;
}

.active {
  color: #6b80f9 !important;
  font-weight: bold;
}
.toolbar {
  text-align: left;
}

.toolbar button {
  padding: 2px;
  margin: 2px;
}

.board {
  text-align: left;
  padding: 2px;
  margin: 2px;
}

.board span {
  display: block;
  width: 100%;
}

.backdrop {
  /* starts directly from top with 0 pixel */
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0,0,0,0.5) !important;
  width: 100%;
  height: 100%
}

.custom_modal {
  width: 400px;
  padding: 20px;
  /* margin: 0 auto; */
  background: #fff;
  border-radius: 10px;
  position: absolute;
  float: left;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
}

.buttons button {
  margin-right: 1rem;
}
</style>
