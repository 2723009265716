<template lang="html">

  <teleport to="body" v-if="modalOpen">
    <div class="backdrop" @click.self="cancel">
      <div class="custom-modal">
        <h1>Delete {{objectName}}</h1>
        <p>Are you sure you want to delete the {{objectName}}?</p>
        <div class="buttons">
          <button class="btn btn-outline-primary" @click="delete">Yes</button>
          <button class="btn btn-outline-primary" @click="cancel">No</button>
        </div>
      </div>
    </div>
  </teleport>

  <div class="folderContainer" :key="change">
    <div class="toolbar">
      <button class="btn btn-secondary" title="New document" @click="$emit('createDoc')">
        <font-awesome-icon :icon="['fas', 'file']" size="1x"/>
      </button>

      <button class="btn btn-secondary" title="New folder" @click="toggleInput">
        <font-awesome-icon :icon="['fas', 'folder']" size="1x"/>
      </button>

      <button class="btn btn-secondary" title="Edit folder" @click="editFolder" :disabled="activeId ==''">
        <font-awesome-icon :icon="['fas', 'edit']" size="1x"/>
      </button>

      <button class="btn btn-secondary" title="Delet selected folder/document" @click="deleteModal" :disabled="activeId =='' && !prevDocument.isSelected">
        <font-awesome-icon :icon="['fas', 'trash']" size="1x"/>
      </button>

      <button class="btn btn-secondary" title="Manage images" @click="$emit('uploads')">
        <font-awesome-icon :icon="['fas', 'file-image']" size="1x"/>
      </button>
    </div>

    <input
      class="form-control"
      v-model="newFolder"
      @keyup.enter="createFolder($event)"
      v-if="newFolderInput"
      placeholder="Enter folder name.."
      >

    <div class="folder">
      <span
        @click="setActiveAndEmitDocFilter()"
        @drop="onDrop($event)"
        @dragenter.prevent
        @dragover.prevent
        >
        /
      </span>
    </div>

    <div class="folder"
      v-for="(folder, index) in folders"
      :key="folder.id"
      :id="folder.id">
      <span
        v-if="folderToChange.id !== folder.id"
        @click="setActiveAndEmitDocFilter(folder)"
        :class="{active: (folder.id == activeId)}"
        @drop="onDrop($event, folder)"
        @dragenter.prevent
        @dragover.prevent
        >
        {{folder.name}}
        <font-awesome-icon :icon="['fas', 'folder-open']"/>
      </span>
      <input
        class="form-control"
        v-model="folderToChange.name"
        @keyup.enter="updateFolder"
        v-if="folderToChange.id == folder.id"
        >

      <div class="subFolder"
        v-for="(subFolder, index) in folder.subFolders"
        :key="subFolder.id"
        :id="subFolder.id"
        @drop="onDrop($event, subFolder)"
        @dragenter.prevent
        @dragover.prevent
        >
        <span
          v-if="folderToChange.id !== subFolder.id"
          :class="{active: (subFolder.id == activeId)}"
          @click="setActiveAndEmitDocFilter(subFolder)">
          {{subFolder.name}}
          <font-awesome-icon :icon="['fas', 'folder-open']"/>
        </span>
        <input
          class="form-control"
          v-model="folderToChange.name"
          @keyup.enter="updateFolder"
          v-if="folderToChange.id == subFolder.id"
          placeholder="Enter folder name.."
          >

        <div class="documents" v-if="subFolder.id == activeId">
          <div class="document"
            v-for="(document, docIndex) in documents"
            :key="document.id"
            draggable="true"
            @dragstart="startDrag($event, document)"
            :class="{activeDoc: (document.isSelected)}"
            @click="markAndOpen($event, document)">

            <span>
              {{document.name}}
              <font-awesome-icon :icon="['fas', 'file-alt']"/>
            </span>
          </div>
        </div>
      </div>

      <div class="subFolder new">
        <input
          class="form-control"
          v-model="newFolder"
          @keyup.enter="createFolder($event, folder)"
          v-if="newSubFolderInput == folder.id"
          placeholder="Enter folder name.."
          >
      </div>

      <div class="documents" v-if="folder.id == activeId">
        <div class="document"
          v-for="(document, docIndex) in filteredDocs"
          :key="document.id"
          draggable="true"
          @dragstart="startDrag($event, document)"
          :class="{activeDoc: (document.isSelected)}"
          @click="markAndOpen($event, document)">

          <span>
            {{document.name}}
            <font-awesome-icon :icon="['fas', 'file-alt']"/>
          </span>
        </div>
      </div>

    </div>

    <div class="rootDocs" v-if="activeId==''">
      <div class="document"
      v-for="(document, docIndex) in filteredDocs"
      :key="document.id"
      draggable="true"
      @dragstart="startDrag($event, document)"
      :class="{activeDoc: (document.isSelected)}"
      @click="markAndOpen($event, document)">

      <span>
        {{document.name}}
        <font-awesome-icon :icon="['fas', 'file-alt']"/>
      </span>
    </div>
  </div>

  </div>
</template>

<script>
export default {
  props: {
    folders: {
      type: Array,
      default: []
    },
    documents: {
      type: Array,
      default: []
    },
    currentDocument: {},
    change: 0,
  },
  emits: ['openDocument', 'filter', 'createDoc', 'delete', 'uploads'],
  data: function() {
    return {
      newFolderInput: false,
      newSubFolderInput: "",
      newFolder: "",
      showInputFolder: false,
      showInputSubFolder: false,
      previousIndex: -1,
      activeId: "",
      activeParent: "",
      folderToChange: {},
      prevDocument: {},
      modalOpen: false,
      objectName: "",
      objectToDelete: "",
    };
  },
  mounted() {
  },
  computed: {
    filteredDocs() {
      return this.documents.filter((doc) => {
        if (doc.folder == this.activeId) {
          if (doc.subFolder == "") {
            return doc
          }
        }
      })
    }
  },
  methods: {
    deleteModal() {
      //open modal
      if (this.prevDocument.isSelected || this.activeId !== "") {
        this.modalOpen = true
        // what should be deleted?
        if (this.prevDocument.isSelected) {
          this.objectName = "document"
          this.objectToDelete = this.prevDocument.id
          this.$emit('delete')
        } else {
          this.objectName = "folder"
          this.objectToDelete = this.activeId
        }
      } else {
        console.log("nothing to delete, nothing selected");
        this.$toast.warning('Nothing to delete.. nothing selected..', {  position: "top"})
      }
    },
    cancel() {
      //cleanup
      this.objectToDelete = ""
      //close modal
      this.modalOpen = false
    },
    delete() {
      var db = this.$store.state.db
      var userDocRef = db.collection('user').doc(this.$store.state.user.uid)
      //close modal
      this.modalOpen = false

      //delete objects
      if (this.objectName == "document") {
        //delete doc
        userDocRef.collection("writer").doc(this.objectToDelete).delete()
        .then(()=> {
          console.log("Document successfully deleted: ", this.objectToDelete);

          this.$toast.success('Document deleted', {  position: "top"})
          //remove indexDoc
          this.$store.state.client.collections(this.$store.state.userSchema).documents(this.objectToDelete).delete()
          .then((element) => {
            console.log('Search index deleted: ', element.id);
          })

          //trigger reload of docs to show proper list in folders
          this.$emit("filter", this.activeId, this.activeParent)
        })
        .catch((error) =>{
          console.log("Error during deletion: ", error);
          this.$toast.error('Error during deletion. Try again later.', {  position: "top"})
        })
      } else {
        //delete folder
        if (this.activeParent !== "") {
          // delete subfolder
          console.log(this.activeParent);
          console.log(this.objectToDelete);

          userDocRef.collection("writer_folders").doc(this.activeParent).collection('subFolders').doc(this.objectToDelete).delete()
         .then(()=> {
            console.log("SubFolder successfully deleted: ", this.objectToDelete);
            this.$toast.success('Subfolder successfully deleted.', {  position: "top"})
          })
          .catch((error) =>{
            console.log("Error during deletion: ", error);
            this.$toast.error('Error during deletion. Try again later.', {  position: "top"})
          })
        } else {
          //delete main folder
          userDocRef.collection("writer_folders").doc(this.objectToDelete).delete()
          .then(()=> {
            console.log("Folder successfully deleted: ", this.objectToDelete);
            this.$toast.success('Folder successfully deleted.', {  position: "top"})
          })
          .catch((error) =>{
            console.log("Error during deletion: ", error);
            this.$toast.error('Error during deletion. Try again later.', {  position: "top"})
          })
        } //endif if (this.activeParent !== "") {

      }

    },
    editFolder() {
      // reset
      this.folderToChange = {}

      // find folder to change
      for (var x = 0; x < this.folders.length; x++) {
        // check if main folder
        if (this.activeId === this.folders[x].id) {
          this.folderToChange = this.folders[x]
          break
        }

        // check in subfolders
        if (this.folders[x].subFolders) {

          for (var i = 0; i < this.folders[x].subFolders.length; i++) {

            if (this.activeId == this.folders[x].subFolders[i].id) {
                this.folderToChange = this.folders[x].subFolders[i]
                break
            }

          } //endfor subfolders

        } //endif subfolders

      }// endfor this.folders
    },
    updateFolder() {
      var db = this.$store.state.db
      var userDocRef = db.collection('user').doc(this.$store.state.user.uid)

      //get folder reference for subFolder or folder
      var newFolderRef = userDocRef.collection("writer_folders")
      if (this.folderToChange.parent) {
        newFolderRef = newFolderRef.doc(this.folderToChange.parent).collection("subFolders").doc(this.folderToChange.id)
      } else {
        newFolderRef = newFolderRef.doc(this.folderToChange.id)
      }

      newFolderRef.set({
        name: this.folderToChange.name,
      }, {merge: true})
      .then(() => {
        console.log("Folder updated", newFolderRef.id, "with name: ", this.folderToChange.name);
      })
      .catch((error) => {
        console.log("Error during folder update: ", error);
        this.$toast.error('Error during folder update. Reload and try again.', {  position: "top"})
      })
      // leave edit & cleanup
      this.folderToChange = {}
    },
    markAndOpen(event, document) {
      // leave edit mode
      this.folderToChange = {}

      // remove previous selection
      this.prevDocument.isSelected = false

      // set current document for selection
      document.isSelected = true
      this.prevDocument = document
      if (event != null) {
        this.$emit('openDocument', event, document)
      }
    },
    setActiveAndEmitDocFilter(folder) {
      // leave edit mode
      this.folderToChange = {}

      //clear potential selected documents
      this.prevDocument.isSelected = false

      // clear and deactivate input fields
      this.newFolderInput = false
      this.newSubFolderInput = ""
      // set active folder
      if (typeof(folder) =="undefined" || this.activeId == folder.id) {
        // if folder is already active toggle
        this.activeId = ""
        this.activeParent = ""
      } else {
        this.activeId = folder.id
        if (typeof(folder.parent) == "undefined") {
          this.activeParent = ""
        } else {
          this.activeParent = folder.parent
        }
      }
      this.$emit("filter", this.activeId, this.activeParent)
    },
    toggleInput(event) {
      //leave edit mode
      this.folderToChange = {}

      //clear newFolder
      this.newFolder = ""
      if (this.activeParent !== "" || this.activeId !== "") {
        // do not show input for main folder
        this.newFolderInput = false
        // set folder Id to show input
        if (this.activeParent !== "") {
          this.newSubFolderInput = this.activeParent
        } else {
          this.newSubFolderInput = this.activeId
        }
      } else {
        // show main folder input
        this.newFolderInput = true
        // clear newSubFolderInput to not display sub folder input
        this.newSubFolderInput = ""
      }
    },
    createFolder(event, folder) {
      var db = this.$store.state.db
      var userDocRef = db.collection('user').doc(this.$store.state.user.uid)

      if (folder) {
        // create subFolder
        var newFolderRef = userDocRef.collection("writer_folders").doc(folder.id).collection("subFolders").doc()
        newFolderRef.set({
          name: this.newFolder,
          parent: folder.id,
          id: newFolderRef.id,
        })
        .then(() => {
          console.log("New subFolder created ", newFolderRef.id);
          // add subFolder, do sub collection listener will not update otherwise
        })
        .catch((error) => {
          console.log("Error during subFolder creation: ", error);
          //TODO error message
        })

      } else {
        var newFolderRef = userDocRef.collection("writer_folders").doc()
        newFolderRef.set({
          name: this.newFolder,
          id: newFolderRef.id,
        })
        .then(() => {
          console.log("New first level folder created ", this.newFolder);
        })
        .catch((error) => {
          console.log("Error during subFolder creation: ", error);
          this.$toast.error('Error folder creation. Try again later.', {  position: "top"})
        })
      } //endif
      //cleanup
      this.newFolder = ""
      this.newFolderInput = false
      this.newSubFolderInput = ""
    },
    startDrag(event, doc) {
      console.log(event)
      console.log(doc)
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('docId', doc.id)
    },
    onDrop(event, folder) {
      // get document that is moved
      const docId = event.dataTransfer.getData('docId')
      // udate folder
      var newFolder = ""
      var newSubfolder = ""
      if (typeof(folder) == "undefined") {
        // do nothing, no folder assigned
      } else {
        if (typeof(folder.parent) == "undefined") {
          //set folder as main folder
          newFolder = folder.id
        } else {
          // set subfolder
          newFolder = folder.parent
          newSubfolder = folder.id
        }
      }
      var db = this.$store.state.db
      var userDocRef = db.collection("user").doc(this.$store.state.user.uid)
      console.log(docId);
      console.log(event.dataTransfer.getData('docId'));
      userDocRef.collection("writer").doc(docId).set({
        'folder': newFolder,
        'subFolder': newSubfolder
      }, {merge: true})
      .then(() => {
        console.log("Document moved to new folder")
        //force reload
        this.$emit("filter", this.activeId, this.activeParent)
      })
      .catch((error) =>{
        console.error("Error moving document: ", error)
        this.$toast.error('Error moving document. Reload and try again.', {  position: "top"})
      })
    },



  },
}
</script>

<style lang="css" scoped>
body {
  font-family: Menlo, Consolas, monospace;
  color: #444;
}

input {
  display: block;
  width: 100%;
}

.folderContainer {
  cursor: pointer;
}
.active {
  color: #6b80f9 !important;
  font-weight: bold;
}
.activeDoc {
  color: #7cacff !important;
  font-weight: bold;
}


.toolbar {
  text-align: left;
}

.toolbar button {
  padding: 2px;
  margin: 2px;
}

.folder {
  text-align: left;
  padding: 2px;
  margin: 2px;
}

.folder span {
  display: block;
  width: 100%;
}

.subFolder {
  padding-left: 1em;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #969696;
}

.documents {
  padding-left: 1em;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #2c393d;

}

.rootDocs {
  /* padding-left: 1em; */
  text-align: left;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #2c393d;

}

.new {
  font-style: italic;
}

.backdrop {
  /* starts directly from top with 0 pixel */
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0,0,0,0.5) !important;
  width: 100%;
  height: 100%
}

.custom_modal {
  width: 400px;
  padding: 20px;
  /* margin: 0 auto; */
  background: #fff;
  border-radius: 10px;
  position: absolute;
  float: left;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
}


</style>
