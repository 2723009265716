<template lang="html">

  <!-- Swimlane Editor Modal  -->
  <teleport to="body" v-if="showSwimlane">
    <div class="backdrop" @click.self="closeSwimlane">
      <div class="custom_modal">
        <h1>Add/Edit Swimlane</h1>

        <input class="form-control" type="text" placeholder="Enter swimlane name.." v-model="item.title">

        <div class="color-picker">
          <label>Pick color of swimlane: </label>
          <input type="color" v-model="item.color">
        </div>

        <div class="buttons">
          <button class="btn btn-outline-primary" @click="closeSwimlane">Save</button>
          <button class="btn btn-outline-primary" v-if="item.title" @click="deleteSwimlane">Delete</button>
        </div>
      </div>
    </div>
  </teleport>


  <!-- Main Editor content -->
  <div class="container">

    <input class="form-control" type="text" v-model="boardName" placeholder="Enter board name">

    <!-- <div class="color-picker">
      <label>Pick board background color</label>
      <input type="color" v-model="boardBackgroundColor">
    </div> -->

    <!-- Area to add and change swimlanes and order -->
    <Kanban
      :change="change"
      :stages="stages"
      :blocks="boardLanes"
      @addItem="addSwimlane"
      @itemClick="editSwimlane">
    </Kanban>

    <div class="buttons">
      <button class="btn btn-outline-primary" @click.prevent="save">Save</button>

      <button class="btn btn-outline-primary" @click.prevent="cancel">Cancel</button>
    </div>

  </div>
</template>

<script>
import Kanban from '@/components/Kanban.vue'

export default {
  props: {
    boardId: {
      type: String,
      default: ""
    },
  },


  components: {
    Kanban,
  },


  emits: ['save', 'cancel'],


  data() {
    return {
      boardName: "",
      boardBackgroundColor: "#cfe2ff",
      boardLanes: [],
      stages: [{ title: 'swimlanes', id: 0, stage: 0, prio: 10, color: '#fff' }],
      showSwimlane: false,
      title: "",
      item: {},
      change: Date.now(),
    }
  },

  mounted() {
    this.loadData()
  },

  watch: {
      boardId: function(val, old) {
        // discard changes and show current selected board
        console.log('Different board selected. Load selected board data and discard changes.');
        console.log('Old board: ', old, ' New board:', val);
        this.$toast.warning('Loading selected board data. Changes discarded!', {position: 'top'})
        if (val == "" || val == undefined) {
          // new board - clear all values
          this.boardName = ""
          this.boardBackgroundColor = "#cfe2ff"
          this.boardLanes = []
          this.item = {}
        }
        this.loadData()

      }
  },

  methods: {
    loadData() {
      // only load if existing board
      if (this.boardId !== "") {
        var db = this.$store.state.db
        var uid = this.$store.state.user.uid

        var boardRef = db.collection('user').doc(uid).collection('planner').doc(this.boardId)
        boardRef.get()
        .then((doc) => {
          console.log('Document found: ', doc.data());
          var data = doc.data();
          this.boardName = data.name;
          this.boardBackgroundColor = data.backgroundColor;
          this.boardLanes = data.swimlanes;
        })
        .catch((error)=>{
          console.log('Error loading document: ', error);
          this.$toast.error('Error loading Kanbanboard. Please reload.', {  position: "top"})
        })
      }
    },

    save() {
      var db = this.$store.state.db
      var uid = this.$store.state.user.uid

      var colRef = db.collection('user').doc(uid).collection('planner')
      var docRef = null
      if (this.boardId == "")
      {
        if (this.boardName == "" || this.boardName == undefined) {
          this.$toast.error('Provide board name before saving.', {position: 'top'})
          return //exit method
        }
        docRef = colRef.doc()
      }
      else
      {
        docRef = colRef.doc(this.boardId)
      }
      docRef.set({
        id: docRef.id,
        name: this.boardName,
        backgroundColor: this.boardBackgroundColor,
        updatedOn: Date.now(),
        // Array {id, title, stage, color}
        swimlanes: this.boardLanes,
      }, {merge: true})
      .then(() => {
        console.log("Create/update of KanbanBoard successfull: ", docRef.id);
        this.$toast.success("Create/update of KanbanBoard successfull: "+this.boardName, {position: "top"})
        this.$emit('save')
      })
      .catch((error)=> {
        console.log("Save/Update not successful. Error: ", error);
        this.$toast.error('Save/Update not successful. Please try again.', {  position: "top"})
      })
    },


    cancel() {
      // todo
      this.$emit('cancel')
    },

    addSwimlane() {
      this.showSwimlane = true
    },

    editSwimlane(item) {
      if (item != null && item != undefined) {
        // set item
        this.item = item
      }
      this.showSwimlane = true

    },

    closeSwimlane() {
      if (this.item.title !== "" && this.item.title !== undefined) {

        this.item.stage = this.stages[0].id
        this.item.createdOn = Date.now()

        // update or add
        if (this.item.id == undefined)
        {
          // new item, set id and prio
          this.item.id = this.boardLanes.length+1
          this.item.prio = 0
          if (this.boardLanes.length > 0)
          {
            var prevPrio = this.boardLanes[this.boardLanes.length-1].prio
            this.item.prio = prevPrio + 100
          } else
          {
            this.item.prio = 100
          }
          // push shallow copy to avoid issues with reference
          this.boardLanes.push(Object.assign({}, this.item))
        }
        else
        {
          // find element to change
          for (var i = 0; i < this.boardLanes.length; i++) {
            if (this.boardLanes[i].id == this.item.id) {
              // assign shallow copy to avoid issue with reference
              this.boardLanes[i] = Object.assign({}, this.item)
            }
          }
        }
        // cleanup
        this.item = {}
      }
      this.showSwimlane = false
    },

    deleteSwimlane() {
      // abort in case of only 1 lane remaining
      if (this.boardLanes.length == 1) {
        this.$toast.error('Last board lane cannot be deleted. Items exist and they have to be assigned to a lane. Create a new lane before you delete this.', {position: 'top'})
        return //exit method and do nothing
      }

      // find item in array
      var itemIndex = this.boardLanes.findIndex(x =>
      x.id == this.item.id)

      // get all items assigned to current lane
      var db = this.$store.state.db
      var uid = this.$store.state.user.uid

      var itemsColRef = db.collection('user').doc(uid).collection('planner').doc(this.boardId).collection('items')

      itemsColRef.where("stage", "==", this.item.id).get()
      .then((querySnapshot) => {

        // remove lane from boardlanes and move existing items
        this.boardLanes.splice(itemIndex, 1)
        var newId = this.boardLanes[0].id // set it to first lane

        console.log('SNAPSHOT: ', querySnapshot);

        // close swimlane popup
        this.item = {}
        this.showSwimlane = false

        // load items and update stage assignment
        querySnapshot.forEach((doc) => {
          db.collection('user').doc(uid).collection('planner').doc(this.boardId).collection('items').doc(doc.id).set(
            {stage: newId,},
            {merge: true}
          )
        });

        // update board -> swimlanes
        var boardRef = db.collection('user').doc(uid).collection('planner').doc(this.boardId)
        boardRef.set(
          {swimlanes: this.boardLanes},
          {merge: true}
        )
        .then(()=>{
          this.$toast.success('Board updated, stage/lane deleted.', {position: 'top'})
        })
        .catch(()=> {
          this.$toast.error('Error during board update. Reload and try again.', {position: 'top'})
        })
        this.$toast.success('Existing assigned items have been moved to first stage/lane.', {position: 'top'})
      })
      .catch((error) => {
        console.log('Error reading items for board: ', error);
        this.$toast.error('Error during update of items. Reload and try again.', {position: 'top'})
      })
    },

    updateBlock(id, status, index) {
      // TODO update index / sort order
      console.log(index);
      this.boardLanes.find(b => b.id === Number(id)).boardLanes = status;
    },


  },
}
</script>

<style lang="css" scoped>
.backdrop {
  /* starts directly from top with 0 pixel */
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0,0,0,0.5) !important;
  width: 100%;
  height: 100%
}

.custom_modal {
  width: 400px;
  padding: 20px;
  /* margin: 0 auto; */
  background: #fff;
  border-radius: 10px;
  position: absolute;
  float: left;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
}

.color-picker label {
  margin-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.buttons button {
  margin-right: 1rem;
}
</style>
