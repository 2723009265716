<template>
  <div class="container">

    <ul class="nav nav-tabs">
      <li class="nav-item">
        <router-link class="nav-link" to="/">Home</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/planner">Planner</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/writer">Writer</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/shared">Shared</router-link>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#"
          v-if="loggedIn"
          @click="logOut">
          Logout
        </a>
        <router-link class="nav-link" v-if="!loggedIn" to="/login">
          Login
        </router-link>
      </li>

    </ul>


  </div>
  <br>

  <div class="container">
    <router-view/>
  </div>
  <br>

  <div class="container">
    <ul class="nav nav-pills">
      <li class="nav-item">
        <router-link class="nav-link" to="/terms">Terms of Service</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/legal">Legal Disclosure & Data Privacy</router-link>
      </li>
    </ul>
  </div>


  <!-- Cookie Banner Modal  -->
  <div class="modalContainer" v-if="showCookieBanner">
    <teleport to="body">
      <div class="backdrop">
        <div class="custom_modal">
          <h1>Welcome to PlanInk.Me</h1>

          <p>This website uses essential cookies to make this site work. You may disable these by changing your browser settings but this may effect how the website functions. All other cookies require your consent. To ensure you get the best experience on our website we use Google Analytics to know what content works best. You can see our privacy policy for more details, or directly opt out.
          <router-link to="/legal">Data Protection Policy</router-link>
          <!-- TODO link to data protection.. -->
          </p>

          <div class="buttons">
            <button class="bt btn-primary" @click="acceptAll">Allow Cookies</button>
            <button class="bt btn-primary" @click="acceptMinimum">Decline</button>
          </div>
        </div>
      </div>
    </teleport>
  </div>

</template>

<script>
  import { auth } from './firebase'
  // import { useState, isTracking, isReady } from "vue-gtag-next";

  export default {
    data: function() {
      return {
        showCookieBanner: true,
      }
    },

    beforeCreate() {
      // check if analytics has been allowed before
      let enableGA = this.$cookies.get('enableGA')
      console.log('enableGA', enableGA);
      if (enableGA == "true") {
        console.log('GA tracking enabled');
        this.$store.state.analytics.setAnalyticsCollectionEnabled(true)
      } else {
        console.log('GA tracking disabled');
        this.$store.state.analytics.setAnalyticsCollectionEnabled(false)
      }
    },

    created() {
      // get cookie settings
      let cookie = this.$cookies.get('showCookieBanner')
      console.log('Cookie: ', cookie);
      if (cookie != null) {
        // set showCookieBanner based on local stored cookie settings
        console.log('set banner');
        if (cookie == "false") {
          this.showCookieBanner = false
        } else {
          this.showCookieBanner = true
        }
        console.log(this.showCookieBanner);
      }
    },

    mounted() {

    },

    methods: {
      logOut() {
        this.$store.state.auth.signOut();
        // navigate to homepage
        this.$router.push('/')
      },

      acceptAll() {
        this.showCookieBanner = false // close Banner
        console.log('Tracking: ', true);
        // console.log('GA ready:' ,isReady);
        this.$store.state.analytics.setAnalyticsCollectionEnabled(true)

        this.$cookies.set('showCookieBanner', false) // store cookie
        this.$cookies.set('enableGA', true)
      },

      acceptMinimum() {
        this.showCookieBanner = false // close Banner
        console.log('Tracking: ',false);
        this.$store.state.analytics.setAnalyticsCollectionEnabled(false)
        this.$cookies.set('showCookieBanner', false) // store cookie
        this.$cookies.set('enableGA', false)
      },

    },

    computed: {
      user: function() {
        return this.$store.state.user
      },
      loggedIn: function() {
        return this.$store.state.loggedIn
      }
    },
  }
</script>

<style scoped>

.router-link-active {
  color: #fff !important;
  background-color: #0d6efd !important;
}

.backdrop {
  /* starts directly from top with 0 pixel */
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0,0,0,0.5) !important;
  width: 100%;
  height: 100%
}

.custom_modal {
  width: 400px;
  padding: 20px;
  /* margin: 0 auto; */
  background: #fff;
  border-radius: 10px;
  position: absolute;
  float: left;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
}
/* moved to base CSS */
</style>
