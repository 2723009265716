import firebase from "firebase/app";
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
// var firebaseui = require('firebaseui');

import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/firebase-storage'
import 'firebase/analytics'


// Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: "AIzaSyDHhMcSzSHMnS3otoEH5m8nbk98V_l1xqk",
  authDomain: "planink-4e0e0.firebaseapp.com",
  projectId: "planink-4e0e0",
  storageBucket: "planink-4e0e0.appspot.com",
  messagingSenderId: "418560804260",
  appId: "1:418560804260:web:f72510495ef3c36acaed31",
  measurementId: "G-J8P665FY4P"
};
// initialize firebase
firebase.initializeApp(firebaseConfig)

// initialize analytics
const analytics = firebase.analytics();
//
// utils
const db = firebase.firestore()
// if (location.hostname === "localhost") {
//   db.useEmulator("localhost", 8088);
// }

const auth = firebase.auth()
// TODO remove before production
// if (location.hostname === "localhost") {
//   auth.useEmulator("http://localhost:9099/auth")
// }

// Get a reference to the storage service, which is used to create references in your storage bucket
const storage = firebase.storage()


// initialize firebase auth ui
const uiConfig = {
  signInSuccessUrl: '/',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID
    ]
  };
const ui = new firebaseui.auth.AuthUI(auth);

//
// // collection references
// const usersCollection = db.collection('users')
// const postsCollection = db.collection('posts')
// const commentsCollection = db.collection('comments')
// const likesCollection = db.collection('likes')
//
// // export utils/refs
export {
  db,
  auth,
  firebaseConfig,
  uiConfig,
  ui,
  storage,
  analytics
  // usersCollection,
  // postsCollection,
  // commentsCollection,
  // likesCollection
}
