import { createRouter, createWebHistory } from 'vue-router'
import Planner from '../views/Planner.vue'
import Writer from '../views/Writer.vue'
import Shared from '../views/Shared.vue'
import Home from '../views/Home.vue'
import { store } from '../store.js'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/planner',
    name: 'Planner',
    component: Planner,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/writer',
    name: 'Writer',
    component: Writer,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/shared',
    name: 'Shared',
    component: Shared,
    meta: {
      requiresAuth: true
    },
  },
  // {
  //   path: '/shared/:docID',
  //   name: 'SharedDoc',
  //   component: Shared,
  //   props: true,
  //   meta: {
  //     requiresAuth: true
  //   },
  // },
  // {
  //   path: '/writer/:workbook', //param workbook
  //   name: 'WriterWorkbook',
  //   component: Writer,
  //   props: true,
  //   meta: {
  //     requiresAuth: true
  //   },
  // },
  {
    path: '/login',
    name: 'Login',
    component: () => import( /* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/legal',
    name: 'Legal',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LegalDisclosure.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsOfService.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

//beforeEach navigation guard to check if route requires authentication

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const toPath = to.fullPath
  // show login if not authenticated for all routes except shared
  if (requiresAuth && !store.state.user && toPath !="/shared") {
    console.log('login required, redirect')
    store.state.analytics.logEvent('screen_view', { screen_name: '/login' })
    next('/login')
  } else {
    store.state.analytics.logEvent('screen_view', { screen_name: toPath })
    next()
  }
})

export default router
