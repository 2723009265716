import { createStore } from 'vuex'
//import firebase auth
import { analytics, auth, db, storage } from './firebase'


//######INIT FULL TEXT SEARCH
const Typesense = require('typesense')
var client = new Typesense.Client({
  'nodes': [{
    'host': '5y0b4s7mlevxtizcp-1.a1.typesense.net', // For Typesense Cloud use xxx.a1.typesense.net
    'port': '443',      // For Typesense Cloud use 443
    'protocol': 'https'   // For Typesense Cloud use https
  }],
  'apiKey': 'mJQi8eaAKTHY5eqOyViYzxHlWq0hdGQD',
  'connectionTimeoutSeconds': 2
})


// create new store instance
const store = createStore({
  state() {
    return {
      user: '{}',
      loggedIn: false,
      auth: {},
      db: {},
      storage: {},
      analytics: {},
      client: client,
      userSchema: "",
    }
  },
  mutations: {
    updateUser(state, user) {
      console.log('User state changed')
      state.user = user
      if (user) {
        console.log('Active user session')
        state.loggedIn = true

        // #### Set user specific index schema
        // client.collections('writer').delete()
        var userSchema = user.uid+'_writer';
        state.userSchema = userSchema;
        let writer = client.collections(userSchema).retrieve()
        .then((schema) =>{
          console.log('user schema found: ', schema);
        })
        .catch((error)=> {
          // create schema
          var schema = {
            'name': userSchema,
            'fields': [
              {
                'name': 'id',
                'type': 'string'
              },
              {
                'name': 'name',
                'type': 'string'
              },
              {
                'name': 'text',
                'type': 'string'
              },
            ],
          }
          client.collections().create(schema)
          console.log('schema created');
        })

        // ### set shared schema if not existing
        var sharedSchema = 'shared'
        let shared =  client.collections('shared').retrieve()
        .then((schema) =>{
          console.log('shared schema found: ', schema);
        })
        .catch((error) => {
          // create schema
          var schema = {
            'name': 'shared',
            'fields': [
              {
                'name': 'id',
                'type': 'string'
              },
              {
                'name': 'text',
                'type': 'string'
              },
            ],
          }
          client.collections().create(schema)
          console.log('shared schema created');
        })

      } else {
        console.log('No active user session')
        state.loggedIn = false
      }
    },
    setGlobalFBInstances(state) {
      console.log('Global auth instance set')
      state.auth = auth
      state.db = db
      state.storage = storage
      console.log('Analytics: ', analytics);
      state.analytics = analytics
    },
  },
  methods: {
  },
})

export {
  store
}
