<template lang="html">

  <div class="row">

    <div class="col-1" v-if="!showMenu">
      <button class="btn btn-light" title="Toggle Menu" @click="toggleMenu">
        <font-awesome-icon :icon="['fas', 'bars']" size="1x"/>
      </button>
    </div>

    <div class="col" v-if="showMenu">
      <button class="btn btn-light" title="Toggle Menu" @click="toggleMenu">
        <font-awesome-icon :icon="['fas', 'bars']" size="1x"/>
      </button>

      <div class="menu" >
        <KanbanMenu :key="change"
          ref="kanbanmenu"
          :preSelected="selectedBoardId"
          :boards="boards"
          @newBoard="openBoardEditor"
          @editBoard="editBoard"
          @boardSelected="boardSelected"
          @deleteBoard="deleteBoard"
          >
        </KanbanMenu>
      </div>

    </div>

    <div class="col-10">
      <div class="content">

        <!-- TODO open existing item -->
        <Kanban
          :change="change"
          :stages="stages"
          :blocks="blocks"
          @drop="dropEventHandler"
          @addItem="addItem"
          @itemClick="showItem"
          v-if="!showEditor">
        </Kanban>

        <KanbanBoardEditor
          :boardId="selectedBoardId"
          v-if="showEditor"
          @save="closeBoardEditor"
          @cancel="closeBoardEditor">
        </KanbanBoardEditor>
      </div>
    </div>

  </div>

  <!-- Itme Viewer/Editor Modal  -->
  <teleport to="body" v-if="editItem">
    <div class="backdrop" @click.self="saveItem">
      <div class="custom-modal">
        <h1>Add/Edit Item</h1>

        <input class="form-control" type="text" placeholder="Enter Item title.." v-model="item.title">
        <br>
        <textarea rows="10" class="form-control" v-model="item.text" placeholder="Describe item..." @input="resizeTextArea($event)"></textarea>
        <br>
        <div class="buttons">
          <button class="btn btn-outline-primary" @click="saveItem">save</button>
          <button class="btn btn-outline-primary" @click="cancelItem">cancel</button>
          <button class="btn btn-danger" v-if="item.id" @click="deleteItem">delete</button>
        </div>
      </div>
    </div>
  </teleport>

</template>

<script>
import Kanban from '@/components/Kanban.vue'
import KanbanMenu from '@/components/KanbanMenu.vue'
import KanbanBoardEditor from '@/components/KanbanBoardEditor.vue'

export default {
  name: 'Planner',
  components: {
    Kanban,
    KanbanMenu,
    KanbanBoardEditor,
  },


  data() {
    return {
      stages: [],
      // ['on-hold', 'in-progress', 'needs-review', 'approved'],
      blocks: [
      //   {id: 1, stage: 2, title: 'test1', prio: 100}, {id: 2, stage: 2, title: 'test2', prio: 200},
      // {id: 3, stage: 1, title: 'test3', prio: 300},
      ],
      showMenu: true,
      showEditor: false,
      selectedBoardId: "",
      change: Date.now(),
      boards: [],
      editItem: false,
      item: {},
    }
  },

  mounted() {
    var db = this.$store.state.db
    var uid = this.$store.state.user.uid
    var colRef = db.collection('user').doc(uid).collection('planner')
    console.log('mounted');
    // get boards
    colRef.onSnapshot((querySnapshot) =>{
      this.boards = [] // clear before refill
      this.stages = [] // clear before refil
      querySnapshot.forEach((doc) => {
        this.boards.push(doc.data())
      })
      // set visible data
      var currentBoard = {}
      if (this.selectedBoardId == "" || this.selectedBoardId == undefined) {
        this.selectedBoardId = this.boards[0].id
        currentBoard = this.boards[0]
      } else {
        // find selected board
        for (var i = 0; i < this.boards.length; i++) {
          if (this.boards[i].id == this.selectedBoardId ) {
            currentBoard = this.boards[i]
          }
        }
      }
      // set lanes and load items
      try {
        this.loadBoardData(currentBoard)
      } catch (e) {
        // in rare cases error can occur: example deletion of board results in:
        // Planner.vue?7f68:139 Uncaught TypeError: Cannot read property 'length' of undefined
        // basically error on board.swimlanes.. not sure how to prevent it as it seems firestore has a hiccup
        // NOT A BREAKING EXCEPTION, JUST LOG IT.. AS SOON AS USER SELECTS NEW BOARD ALL GOOD
        console.log(e);
      }
    })

  },

  methods: {
    loadBoardData(board) {
      var db = this.$store.state.db
      var uid = this.$store.state.user.uid

      //set board lanes
      this.stages = [] //clear
      for (var i = 0; i < board.swimlanes.length; i++) {
        this.stages.push(board.swimlanes[i])
      }

      // load items/blocks for board
      this.blocks = [] // clear
      var itemsCol = db.collection('user').doc(uid).collection('planner').doc(board.id).collection('items')

      itemsCol.get()
      .then((snapshot) =>{
        snapshot.forEach((doc) => {
          this.blocks.push(doc.data())
        })
      })
      .catch((error) => {
        console.log('Error during reading of items: ', error);
        this.$toast.error('Error loading Kanbanboard information. Please reload.', {  position: "top"})
      })
    },

    showItem(block, stage){
      console.log('Show item: ', block, stage);
      this.item = Object.assign({}, block)
      this.item.stage = Object.assign({}, stage)
      this.editItem = true;
      // TODO
    },

    addItem(stage) {
      console.log('Add item for ', stage);
      this.item.stage = stage
      this.editItem = true;
    },

    deleteItem() {
      var db = this.$store.state.db
      var uid = this.$store.state.user.uid

      var item = this.item
      var itemRef = db.collection('user').doc(uid).collection('planner').doc(this.selectedBoardId).collection('items').doc(item.id)

      itemRef.delete()
      .then(()=>{
        // remove item from array
        let index =  this.blocks.findIndex(x => x.id == item.id)
        this.blocks.splice(index, 1)
        console.log('Deletion of item: ', item.id, 'successfull');
        this.$toast.success('Item deleted', {position: 'top'})
        this.cancelItem()
      })
      .catch((error) => {
        console.log('Error during deletion of item: ', item.id);
        this.$toast.error('Error during deletion. Reload and try again.')
        this.cancelItem()
      })
    },

    saveItem() {
      var db = this.$store.state.db
      var uid = this.$store.state.user.uid

      // check if board id is set
      if (this.selectedBoardId == "" || this.selectedBoardId == undefined) {
        // throw error that user inserts title
        this.$toast.error('Internal error, please reload and try again.', {  position: "top"})
        this.editItem = false
        return  // exit method
      }

      // data validation
      if (this.item.text !== "" && (this.item.title == undefined || this.item.title == "")) {
        // throw error that user inserts title
        this.$toast.warning('No title. Please enter title or cancel.', {  position: "top"})
        return  // exit method
      }

      // update or create new
      if (this.item.title !== "" && this.item.title !== undefined) {
        var itemsCol = db.collection('user').doc(uid).collection('planner').doc(this.selectedBoardId).collection('items')  // get items collection reference

        if (this.item.id == undefined) // create new item
        {
          var newPrio = 0
          if (this.blocks.length == 0) {
            newPrio = 1000
          }
          else
          {
            newPrio = this.blocks[this.blocks.length-1].prio + 1000 //last element in array has highest prio number
          }
          if (this.item.text == undefined) {
            this.item.text = ""
          }

          var newItem = itemsCol.doc()
          newItem.set({
            id: newItem.id,
            stage: this.item.stage.id,
            title: this.item.title,
            prio: newPrio,
            text: this.item.text,
            createdOn: Date.now(),
            updatedOn: Date.now(),
          })
          .then(() => {
            console.log('Item successfully created: ', newItem.id);
            this.$toast.success('Item created successfully', {position: 'top'})
            // cleanup and close
            this.cancelItem()
            console.log(newItem);
            // load new board items
            let currentBoard = this.boards.find(x => x.id == this.selectedBoardId)
            this.loadBoardData(currentBoard)
          })
          .catch((error) => {
            console.log('Error during item creation: ', error);
            this.$toast.error('Something went wrong, please reload and try again', {  position: "top"})
          })
        }
        else // update item
        {
          var existingItem = itemsCol.doc(this.item.id)
          existingItem.set({
            title: this.item.title,
            text: this.item.text
          }, {merge: true})
          .then(() => {
            console.log('Item successfully updated: ', existingItem.id);
            this.$toast.success('Item updated successfully', {position: 'top'})
            // cleanup and close
            this.cancelItem()
            // load updated board items
            let currentBoard = this.boards.find(x => x.id == this.selectedBoardId)
            this.loadBoardData(currentBoard)
          })
          .catch((error) => {
            console.log('Error during item update: ', error);
            this.$toast.error('Something went wrong, please reload and try again', {  position: "top"})
          })
        }
      }
      else // no action, no title, no text maintained
      {
        // cleanup and close
        this.cancelItem()
      } //endif minimum data


    },

    cancelItem() {
      // cleanup and close modal
      this.item = {};
      this.editItem = false;
    },

    toggleMenu() {
      this.showMenu = !this.showMenu
    },

    openBoardEditor() {
      this.selectedBoardId = ""
      this.showEditor = true
    },

    editBoard() {
      // console.log(this.$refs.kanbanmenu.activeId);
      // this.selectedBoardId = this.$refs.kanbanmenu.activeId;
      this.showEditor = true
    },

    boardSelected(board) {
      console.log('select board', board);

      this.selectedBoardId = board.id
      for (var i = 0; i < this.boards.length; i++) {
        // load lanes and blocks of selected board
        if (this.boards[i].id == board.id) {
          let boardObj = this.boards[i]
          this.loadBoardData(boardObj)
          break; // exit loop
        }
      }
    },

    closeBoardEditor() {
      this.showEditor = false
      // update happens in mounted() <- called several times in case of changes
    },

    deleteBoard(id) {
      // Delete all sub collections and documents
      var db = this.$store.state.db
      var uid = this.$store.state.user.uid

      var boardRef = db.collection('user').doc(uid).collection('planner').doc(id)
      var itemsColRef = boardRef.collection('items')

      // get all items assigned to current lane
      itemsColRef.get()
      .then((querySnapshot) => {
        querySnapshot.forEach((item, i) => {
          itemsColRef.doc(item.id).delete()
          .then(() => {
            console.log('Item deleted ', i);
          })
          .catch((error) => {
            console.log('Error during item deletion: ', error);
          })
        });
        // delete board
        boardRef.delete()
        .then(()=>{
          this.$toast.success('Board and all its items deleted.', {position: 'top'})
        })
        .catch((error) => {
          console.log('Error during board deletion: ', error);
          this.$toast.error('Error during deletion. Please reload and try again.', {position: 'top'})
        })
      })
      .catch((error) => {
        console.log('Error during item read for board deletion: ', error);
        this.$toast.error('Error during deletion. Please reload and try again.', {position: 'top'})
      })

      // remove board from array
      let index = this.boards.findIndex(x => x.id == id)
      this.boards.splice(index, 1)
    },

    dropEventHandler(block, targetStage, updatedArray) {
      // update local data and update firebase
      if (block.stage == targetStage.id) {
        // do nothing
      } else {
        let blockToChange = this.blocks.find(x => x.id == block.id)
        blockToChange.stage = targetStage.id
      }
      var db = this.$store.state.db
      var uid = this.$store.state.user.uid
      var itemRef = db.collection('user').doc(uid).collection('planner').doc(this.selectedBoardId).collection('items').doc(block.id)  //get item ref
      itemRef.set({
        prio: block.prio,
        stage: targetStage.id
      }, {merge: true})
      .then(() =>{
        console.log('Item updated after drop: ', block.id);
      })
      .catch((error) => {
        console.log('Error during update after drop: ', error);
      })

    },

    resizeTextArea(e) {
      e.target.style.height = 'auto'
      e.target.style.height = e.target.scrollHeight + 'px'
    },

  }
}
</script>

<style lang="css">
.backdrop {
  /* starts directly from top with 0 pixel */
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0,0,0,0.5) !important;
  width: 100%;
  height: 100%
}

.custom-modal {
  width: 400px;
  padding: 20px;
  /* margin: 0 auto; */
  background: #fff;
  border-radius: 10px;
  position: absolute;
  float: left;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
}

.buttons button {
  margin-right: 1rem;
}

</style>
