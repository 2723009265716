<template lang="html">
  <div class="drag-container" :key="change">

    <ul class="drag-list">

      <li
        v-for="stage in stages"
        class="drag-column"
        :class="{['drag-column-' + stage.id]: true}" :key="stage.id"
        @drop="drop($event, stage)"
        @dragenter.prevent
        @dragover.prevent
        :style="{backgroundColor:stage.color}"
        >

        <span class="drag-column-header">
          <slot :name="stage.title">
            <h2>{{ stage.title }}</h2>
          </slot>
        </span>

        <div class="drag-options"></div>

        <ul class="drag-inner-list" ref="list" :data-status="stage.id">
          <li><button class="btn btn-outline-primary" @click="$emit('addItem', stage)">Add item</button></li>
          <li
            class="drag-item"
            v-for="block in getBlocks(stage)"
            :data-block-id="block[idProp]" :key="block[idProp]"
            draggable="true"
            @click="$emit('itemClick', block, stage)"
            @dragstart="drag($event, block)"
            @dragend="dragend($event, block)"
            @dragover="dragover($event, block)"
            :style="{backgroundColor:block.color}"
            >
            <slot :name="block[idProp]">
              <strong>{{ block[titleProp] }}</strong>
              <div> <p>{{ block[textProp] }}</p></div>
            </slot>
          </li>

        </ul>

        <div class="drag-column-footer">
            <slot :name="`footer-${stage}`"></slot>
        </div>
      </li>

    </ul>

  </div>

</template>

<script>
// Uses dragula for drag and drop
// https://github.com/bevacqua/dragula#dragulacontainers-options
// import dragula from 'dragula';

export default {
  name: 'Kanban',

  props: {
    change: {
      type: Number,
      required: true,
    },
    stages: {
      type: Array,
      required: true,
    },
    blocks: {
      type: Array,
      required: true,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    idProp: {
      type: String,
      default: 'id',
    },
    textProp: {
      type: String,
      default: 'text',
    },
    statusProp: {
      type: String,
      default: 'stage',
    },
    titleProp: {
      type: String,
      default: 'title',
    },
    allowAll: {
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {
      movingBlock: {},
      movingElement: {},
      // sortOrder: {order: '', referenceId: ''},
      moveBefore: null,
    };
  },

  computed: {
    // localBlocks() {
    //   return this.blocks;
    // },
  },

  methods: {
    getBlocks(stage) {
      var sortedBlocks = []
      if (this.blocks.length > 1)
      {
        sortedBlocks = this.blocks.sort(this.compare)
      }
      else
      {
        sortedBlocks = this.blocks
      }
      return sortedBlocks.filter(block => block[this.statusProp] === stage.id);
    },

    drag(e, block) {
      console.log(e, block);
      e.srcElement.classList.add('is-moving');
      this.movingBlock = block
      this.movingElement = e.target;
      this.$emit('drag', e, block);
    },

    dragend(e, block) {
      console.log('dragend: ', e);
      e.srcElement.classList.remove('is-moving');
    },

    dragover(e) {
      // Change sort order within parent element

      if (e.target.parentNode === this.movingElement.parentNode) { //check if in same pare

        if (this.isBefore(this.movingElement, e.target)) {
          // console.log('moveUp: ', e.target);
          let referenceBlockId = e.target.getAttribute("data-block-id")
          // update html to display right order
          e.target.parentNode.insertBefore(this.movingElement, e.target);

          // ideally just update vue data and re-render
          this.moveBefore = referenceBlockId
        }
        else
        {

          try {
            let referenceBlockId = e.target.nextSibling.getAttribute("data-block-id")
            // ideally just update vue data and re-render
            this.moveBefore = referenceBlockId
          } catch (e) {
            // move item to the end of the line
            this.moveBefore = this.blocks.length
          }

          // update html to display right order
          e.target.parentNode.insertBefore(this.movingElement, e.target.nextSibling);


        }

      }

    },

    drop(e, stage) {
      console.log('drop');
      console.log(e, this.movingBlock, stage);
      console.log('Move item before: ', this.moveBefore);
      console.log('---------------------');
      // trigger change of column
      // sort order should always be checked
      var updatedBlocks = {}
      if (this.blocks.length > 1) {
         updatedBlocks = this.prepareDropResult(this.movingBlock, stage, this.moveBefore)
      }
      else
      {
        console.log('No change on drop, no other item')
      }

      e.stopImmediatePropagation() // prevent event from firing twice (event bubbing)
      this.$emit('drop', this.movingBlock, stage, updatedBlocks);
    },

    prepareDropResult(block, targetStage, moveBefore) {
      var currentBlocks = this.blocks.slice() //copy array without reference

      if (moveBefore == null) {
        console.log('No change on drop, no re-order');
        return
      }

      var currentItemIndex = null
      var newTargetIndex = null

      // push item to end
      if (moveBefore == currentBlocks.length)
      {

        newTargetIndex = moveBefore-1
        currentItemIndex = currentBlocks.findIndex(x => x.id == block.id)
        // remove current item
        let moveItem = currentBlocks.splice(currentItemIndex, 1)[0]
        // insert at end of array
        currentBlocks.push(moveItem)

      }

      else
      {

        // get target index and index of current element
        newTargetIndex = currentBlocks.findIndex(x => x.id == moveBefore)
        currentItemIndex = currentBlocks.findIndex(x => x.id == block.id)
        // move the dropped item to new array position => this has not yet impact on assigned priority
        if (currentItemIndex > newTargetIndex) {
          // just remove and insert, no change of newTarget index due to removal
          let moveItem = currentBlocks.splice(currentItemIndex, 1)[0] //return removed item
          currentBlocks.splice(newTargetIndex, 0, moveItem)
          console.log(moveItem);
        }
        else  //dropped Item before target index
        {
          // newTarget index has to be reduced by -1 as the removal of object will impact index
          newTargetIndex = newTargetIndex - 1
          let moveItem = currentBlocks.splice(currentItemIndex, 1)[0] // retun removed item
          currentBlocks.splice(newTargetIndex, 0, moveItem)
        }

      } // end handle end of array and in between

      // #### START PRIO LOGIC ####
      console.log('Evaluate position: ', newTargetIndex);
      console.log(currentBlocks);
      // Evaluate and assign Position/Priority based on target Position

      if (newTargetIndex == (currentBlocks.length-1)) //handle end of array
      {
        // calculate new prio
        currentBlocks[newTargetIndex].prio = (currentBlocks[newTargetIndex-1].prio) + 1000
      }
      else  // handle all other cases (insert before, middle etc..)
      {

        if ( currentBlocks[newTargetIndex].prio == currentBlocks[newTargetIndex+1].prio) //handle edge case, same prio
        {
          // set prio to predecessor
          currentBlocks[newTargetIndex].prio = currentBlocks[newTargetIndex-1].prio
          // reduce predecessor prio by 1
          currentBlocks[newTargetIndex-1].prio = currentBlocks[newTargetIndex-1].prio - 1
          // repeat reduce predecessor prio by 1 till value is not equal to movedItem prio
          while (currentBlocks[newTargetIndex].prio == currentBlocks[newTargetIndex-1])
          {
            currentBlocks[newTargetIndex-1].prio = currentBlocks[newTargetIndex-1].prio - 1
          }
        }
        else //calculate prio in standard case (insert after/before)
        {
          // determine if i-1 & i+1 is in range, otherwise set prio to 0
          let indexMinusOne = newTargetIndex - 1
          let indexPluseOne = newTargetIndex + 1
          let prevPrio = 0
          if (indexMinusOne >= 0) {
            prevPrio = currentBlocks[indexMinusOne].prio
          }
          let succPrio = 0
          if (indexPluseOne <= (currentBlocks.length-1)) {
            succPrio = currentBlocks[indexPluseOne].prio
          }
          // calculate new prio
          currentBlocks[newTargetIndex].prio = (succPrio + prevPrio) / 2
        } //end standard cases if

      }//end prio calculation ifs

      console.log('New priority: ', currentBlocks[newTargetIndex].prio);
      console.log('updated array: ', currentBlocks);
      return currentBlocks;
    }, //endmethod

    isBefore(el1, el2) {
      // check if element is before or after new draged element
      for (var cur = el1.previousSibling; cur && cur.nodeType !== 9; cur = cur.previousSibling) {
        if (cur === el2)
          return true;
      }
      return false;
    },

    compare(a, b) {
      const prioA = a.prio;
      const prioB = b.prio;

      let comparison = 0;
      if (prioA > prioB) {
        comparison = 1;
      } else if (prioA < prioB) {
        comparison = -1;
      }
      return comparison;
    }

  },

  beforeUpdate() {
  },

  mounted() {
  },

  created() {
  },

}

</script>

<style lang="css" scoped>
/* @import './../node_modules/dragula/dragula' */

.drag-column {
  .drag-column-header > div {
    width: 100%;
    h2 > a {
      float: right;
    }
  }
}
.drag-column-footer > div {
    margin-left: 10px;
    a {
        text-decoration: none;
        color: white;
        &:hover {
            text-decoration: underline;
        }
    }
}
.drag-column-on-hold {
    .drag-column-header,
    .is-moved,
    .drag-options {
      background: #FB7D44;
    }
}

.drag-column-in-progress {
    .drag-column-header,
    .is-moved,
    .drag-options {
      background: #2A92BF;
    }
  }

.drag-column-needs-review {
    .drag-column-header,
    .is-moved,
    .drag-options{
      background: #F4CE46;
    }
  }

.drag-column-approved {
    .drag-column-header,
    .is-moved,
    .drag-options {
      background: #00B961;
    }
  }


.section {
  padding: 20px;
  text-align: center;
  a {
    color: white;
    text-decoration: none;
    font-weight: 300;
  }
  h4 {
    font-weight: 400;
    a {
      font-weight: 600;
    }
  }
}

ul.drag-list, ul.drag-inner-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.drag-container {
  max-width: 1000px;
  margin: 20px auto;
}

.drag-list {
  display: flex;
  align-items: flex-start;
}
@media (max-width: 690px) {
  .drag-list {
    display: block;
  }
}

.drag-column:only-child {
  margin-right: 0px;
}

.drag-column {
  flex: 1;
  margin-right: 10px;
  position: relative;
  background: rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 5px;
  border: dashed;
  border-width: thin;
  border-color: #cfe2ff;
}
@media (max-width: 690px) {
  .drag-column {
    margin-bottom: 30px;
  }
}
.drag-column h2 {
  font-size: 1rem;
  margin: 0;
  text-transform: uppercase;
  font-weight: 600;
}

.drag-column-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.drag-inner-list {
  min-height: 50px;
  color: white;
}

.drag-inner-list button {
  margin: 10px;
}

.drag-item {
  padding: 10px;
  margin: 10px;
  height: 100px;
  background: rgba(0, 0, 0, 0.4);
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 5px;
  word-wrap: break-word;
  overflow: hidden;
}
.drag-item.is-moving {
  transform: scale(1.5);
  background: rgba(0, 0, 0, 0.8);
}

.drag-header-more {
  cursor: pointer;
}

.drag-options {
  position: absolute;
  top: 44px;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  transform: translateX(100%);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.drag-options.active {
  transform: translateX(0);
  opacity: 1;
}
.drag-options-label {
  display: block;
  margin: 0 0 5px 0;
}
.drag-options-label input {
  opacity: 0.6;
}
.drag-options-label span {
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 400;
  margin-left: 5px;
}

/* Dragula CSS  */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  list-style-type: none;
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.gu-transit {
  opacity: 0.2;
}

</style>
